import React, { useState, useEffect, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { Canvas as ThreeCanvas, useThree, extend } from '@react-three/fiber';
import { OrbitControls, Html } from '@react-three/drei';
import * as THREE from 'three';
import { products } from '../utils/productData';
import CDCaseModel from '../components/3d/CDCaseModel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

extend({ OrbitControls });

const Scene = ({ children }) => {
  const { gl, scene } = useThree();

  useEffect(() => {
    if (scene && gl) {
      scene.background = new THREE.Color(0xf0f0f0);
      gl.setClearColor(0xf0f0f0);
      gl.shadowMap.enabled = true;
    }
  }, [gl, scene]);

  return (
    <>
      <ambientLight className="bg-white text-black" intensity={0.5} />
      <directionalLight position={[0, 10, 5]} intensity={1} castShadow />
      <OrbitControls enablePan={false} enableZoom={true} minDistance={10} maxDistance={15} />
      {children}
    </>
  );
};

const ProductPage = () => {
  const { productName } = useParams();
  const [product, setProduct] = useState(null);
  const [activeCanvas, setActiveCanvas] = useState('front');
  const [uploadedImages, setUploadedImages] = useState([]);
  const [textures, setTextures] = useState({ front: null, back: null, cd: null });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTextures, setSelectedTextures] = useState({ front: null, back: null, cd: null });

  useEffect(() => {
    const foundProduct = products.find(p => p.name.toLowerCase() === productName);
    setProduct(foundProduct);
  }, [productName]);

  if (!product) {
    return <div className="text-center text-2xl text-red-600 mt-12">Loading product...</div>;
  }

  const uploadImage = (e) => {
    const files = e.target.files;
    if (files.length > 0 && uploadedImages.length + files.length <= 3) {
      setIsLoading(true);
      Promise.all(Array.from(files).map(file => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (f) => {
            const data = f.target.result;
            const tempImage = new Image();
            tempImage.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              const maxSize = Math.min(tempImage.width, tempImage.height);
              canvas.width = maxSize;
              canvas.height = maxSize;
              const offsetX = (tempImage.width - maxSize) / 2;
              const offsetY = (tempImage.height - maxSize) / 2;
              ctx.drawImage(tempImage, offsetX, offsetY, maxSize, maxSize, 0, 0, maxSize, maxSize);
              const croppedDataURL = canvas.toDataURL('image/jpeg');
              resolve({ id: Date.now(), src: croppedDataURL });
            };
            tempImage.src = data;
          };
          reader.readAsDataURL(file);
        });
      })).then(newImages => {
        setUploadedImages(prev => [...prev, ...newImages]);
        setIsLoading(false);
      });
    } else {
      alert("You can upload up to 3 images only.");
    }
  };

  const removeImage = (id) => {
    setUploadedImages(prev => prev.filter(img => img.id !== id));
    setTextures(prev => {
      const newTextures = { ...prev };
      Object.keys(newTextures).forEach(side => {
        if (newTextures[side] && newTextures[side].id === id) {
          newTextures[side] = null;
        }
      });
      return newTextures;
    });
  };

  const applyTexture = (image) => {
    setSelectedTextures(prev => ({ ...prev, [activeCanvas]: image }));
  };

  return (
    <div className="w-full h-screen  overflow-y-auto">
      <div className="w-full mx-auto mt-14  flex items-start flex-col justify-start p-4 lg:py-20">
        <div className="lg:w-4/6 lg:mx-auto w-full">
          <h3 className="text-3xl-s md:text-3xl lg:text-4xl mb-4 uppercase sticky top-0 z-10">{product.name} Customizer</h3>
          <div className="bg-white bg-opacity-40 rounded-lg shadow-lg overflow-hidden backdrop-blur-lg">
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/3 flex flex-col p-4 border-b md:border-b-0 md:border-r border-gray-300 sticky " style={{ backgroundColor: "#292929" }}>
                <h3 className="text-white text-lg mb-4 font-semibold uppercase">Select View</h3>
                <div className="flex flex-row md:flex-col gap-2 mb-6">
                  {['front', 'back', 'cd'].map((side) => (
                    <button
                      key={side}
                      className={`py-2 px-4 border-2 uppercase border-orange-500 rounded-lg cursor-pointer transition duration-300 ${activeCanvas === side ? 'bg-gray-700 text-white' : 'hover:bg-blue-500 hover:text-white'}`}
                      onClick={() => setActiveCanvas(side)}
                    >
                      {side.charAt(0).toUpperCase() + side.slice(1)}
                    </button>
                  ))}
                </div>
                <h3 className="text-white text-lg mb-4 font-semibold uppercase">Upload Images (Max 3)</h3>
                <label className="block py-2 px-1 bg-orange-500 rounded-lg text-white cursor-pointer transition uppercase duration-300 hover:bg-gray-700 text-center mb-6">
                  Upload Image
                  <input type="file" onChange={uploadImage} accept="image/*" multiple className="hidden" />
                </label>
                <h3 className="text-white text-lg mb-4 font-semibold uppercase">Uploaded Images</h3>
                <div className="grid lg:grid-cols-2 grid-cols-4 gap-4">
                  {uploadedImages.map((img) => (
                    <div key={img.id} className="flex flex-col">
                      <div className="relative w-full pb-[100%]">
                        <img
                          src={img.src}
                          alt="Uploaded"
                          onClick={() => applyTexture(img)}
                          className="absolute top-0 left-0 w-full h-full rounded-xl opacity-80 object-cover cursor-pointer"
                        />
                        {selectedTextures[activeCanvas]?.id === img.id && (
                          <div className="absolute top-2 right-2">
                            <CheckCircleIcon className="text-green-500 text-xl" />
                          </div>
                        )}
                      </div>
                      <button 
                        onClick={() => removeImage(img.id)} 
                        className="mt-2 py-1 px-2 bg-red-500 text-white rounded-md cursor-pointer transition duration-300 hover:bg-red-700 text-xs md:text-sm w-full"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full md:w-2/3 h-[40vh] md:h-[70vh] relative">
                <ThreeCanvas camera={{ position: [0, 0, 3], fov: 50 }}>
                  <Scene>
                    <Suspense fallback={<Html center><div className="text-primary-color">Loading 3D Model...</div></Html>}>
                      <CDCaseModel selectedTextures={selectedTextures} activeCanvas={activeCanvas} />
                    </Suspense>
                  </Scene>
                </ThreeCanvas>
                {isLoading && <div className="bg-white bg-opacity-80 text-primary-color text-lg flex items-center justify-center absolute top-0 left-0 w-full h-full">Processing Image...</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Login from './Login';
import TextLogo from '../assets/iconlogo.png';

const Navbar = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const NavItem = ({ to, children, onClick }) => {
    const baseClassName = "cursor-pointer nav-item"; // Add cursor-pointer here
    if (isHomePage) {
      return (
        <ScrollLink to={to} smooth={true} duration={500} className={baseClassName} onClick={onClick}>
          {children}
        </ScrollLink>
      );
    } else {
      return (
        <Link to={`/#${to}`} className={baseClassName} onClick={onClick}>
          {children}
        </Link>
      );
    }
  };

  const handleLoginClick = () => setIsLoginOpen(true);
  const handleLoginClose = () => setIsLoginOpen(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <>
      <nav className={`fixed top-0 left-0 w-full z-50 transition-colors duration-300 ${isScrolled || isMenuOpen ? 'bg-[#1a1a1a]' : 'bg-[#1a1a1a]/20'}`}>
        <div className="lg:w-4/6 w-full mx-auto px-4 sm:px-6 lg:px-4">
          <div className="flex justify-between items-center h-16">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="flex items-center">
                <img src={TextLogo} alt="DripChip" className="h-8" />
              </Link>
            </div>
            <div className="hidden md:flex items-center space-x-8">
              <NavItem to="home">HOME</NavItem>
              <NavItem to="products">PRODUCTS</NavItem>
              <button 
                onClick={handleLoginClick} 
                className="nav-button bg-green-500 px-4 py-1 rounded text-white font-bold hover:bg-[#f56363] transition-colors cursor-pointer"
              >
                LOGIN
              </button>
              <ShoppingBagIcon className="text-white text-2xl cursor-pointer" />
            </div>
            <div className="md:hidden flex items-center">
              <button onClick={handleLoginClick} className="nav-button bg-[#ff6b6b] px-4 py-1 rounded text-white font-bold hover:bg-[#f56363] transition-colors mr-2">LOGIN</button>
              <ShoppingBagIcon className="text-white text-2xl mr-2" />
              <button onClick={toggleMenu} className="text-white">
                {isMenuOpen ? <CloseIcon sx={{ fontSize: 30 }} /> : <MenuIcon sx={{ fontSize: 30 }} />}
              </button>
            </div>
          </div>
        </div>
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-4 pt-2 pb-3 space-y-4 sm:px-3 flex text-right flex-col">
              <NavItem to="home" onClick={toggleMenu}>HOME</NavItem>
              <NavItem to="products" onClick={toggleMenu}>PRODUCTS</NavItem>
            </div>
          </div>
        )}
      </nav>
      <Login isOpen={isLoginOpen} onClose={handleLoginClose} />
    </>
  );
};

export default Navbar;

import React from 'react';
import PolicyPage from '../pages/PolicyPage';

const TermsOfService = () => {
  const content = (
    <>
      <h2>Terms of Service</h2>
      <p>By accessing or using the DripChip website, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our service.</p>
      <h2>Use License</h2>
      <p>Permission is granted to temporarily download one copy of the materials (information or software) on DripChip's website for personal, non-commercial transitory viewing only.</p>
      <h2>Disclaimer</h2>
      <p>The materials on DripChip's website are provided on an 'as is' basis. DripChip makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>
    </>
  );

  return <PolicyPage title="Terms of Service" content={content} />;
};

export default TermsOfService;
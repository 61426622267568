    import React from 'react';
    import { Element } from 'react-scroll';
    import { Link } from 'react-router-dom';
    import { products } from '../utils/productData';

    const ProductCard = ({ product, frontPreview, backPreview }) => (
        <div className="flex flex-col sm:flex-row bg-[#2a2a2a] rounded-lg w-full gap-4 p-4">  
          <div className="sm:w-2/3 aspect-square">
            <img src={product.image} alt={product.name} className="w-full h-full object-cover rounded-lg" />
          </div>
          <div className="flex flex-col sm:w-1/3 justify-between">
            <div className="h-[48%]">
              <img src={frontPreview} alt="Front Preview" className="w-full h-full object-cover rounded-lg" />
            </div>
            <div className="h-[48%]">
              <img src={backPreview} alt="Back Preview" className="w-full h-full object-cover rounded-lg" />
            </div>
          </div>
        </div>
      );
      
      const Products = () => (
        <Element name="products" className="bg-[#1a1a1a] min-h-[400px] xl:w-4/6 w-full mx-auto text-white lg:p-8 p-4">
          <div className="w-full">
            <div className="flex lg:flex-row flex-col">
              <div className="lg:w-3/4">
                <div className="grid grid-cols-1 gap-8">
                  {products.map(product => (
                    <ProductCard 
                      key={product.id} 
                      product={product} 
                      frontPreview={product.frontPreview}
                      backPreview={product.backPreview}
                    />
                  ))}
                </div>
              </div>
              <div className="lg:w-1/4 text-white flex flex-col lg:pl-8 lg:p-0 p-4">
                <div className="flex flex-col">
                  <h3 className="text-xl font-bold mb-4">Why Choose {products[0]?.name || 'Our Product'}?</h3>
                  <p className="text-base mb-4">
                    Experience the perfect blend of nostalgia and cutting-edge technology. Our NFC-powered wearables offer unparalleled convenience and style, enhancing your music experience like never before.
                  </p>
                </div>
                <Link 
                  to={`/products/${products[0]?.name.toLowerCase() || ''}`} 
                  className="block py-2 px-4 bg-orange-500 rounded-lg text-white cursor-pointer transition uppercase duration-300 hover:bg-gray-700 text-center mt-4"
                >
                  Create Your CDigit
                </Link>
              </div>
            </div>
          </div>
        </Element>
      );

    export default Products;
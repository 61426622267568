import FullPreview from "../assets/fullpreview.png";
import FrontPreview from "../assets/frontpreview.png";
import BackPreview from "../assets/backpreview.png";

export const products = [
  {
    id: 1,
    name: 'CDigits',
    description: 'NFC-powered wearables inspired by CD nostalgia.',
    image: FullPreview,
    frontPreview: FrontPreview,
    backPreview: BackPreview,
  },
];
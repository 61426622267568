import React, { useState } from 'react';

const Login = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here
    console.log('Login submitted', { email, password });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-[1001]">
      <div className="bg-[#1a1a1a] p-8 rounded-lg w-96 max-w-md relative shadow-2xl">
        <h2 className="text-3xl-s lg:text-3xl font-bold mb-6 text-white text-center uppercase">Login</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">Email</label>
            <input
              id="email"
              type="email"
              placeholder="your@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-4 py-2 rounded bg-[#2a2a2a] text-white placeholder-gray-400 border border-[#333] focus:outline-none focus:ring-2 focus:ring-[#ff6b6b]"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-1">Password</label>
            <input
              id="password"
              type="password"
              placeholder="••••••••"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full px-4 py-2 rounded bg-[#2a2a2a] text-white placeholder-gray-400 border border-[#333] focus:outline-none focus:ring-2 focus:ring-[#ff6b6b]"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-[#ff6b6b] text-white py-2 px-4 rounded hover:bg-[#f56363] transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#ff6b6b] focus:ring-opacity-50"
          >
            Login
          </button>
        </form>
        <button
          className="absolute top-2 right-2 text-white hover:text-gray-300 transition duration-300 ease-in-out"
          onClick={onClose}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Login;
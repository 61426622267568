import React from 'react';

const PolicyPage = ({ title, content }) => {
  return (
    <div className="max-w-3xl mx-auto px-5 py-10">
      <h1 className="text-[#ff6b6b] mb-8 text-3xl font-bold">{title}</h1>
      <div className="bg-[#1a1a1a] p-8 rounded-lg text-white">
        {content}
      </div>
    </div>
  );
};

export default PolicyPage;
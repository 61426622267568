import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import MollieBadge from '../assets/badge.png';
import TextLogo from '../assets/textlogo.png';
import IconLogo from '../assets/iconlogo.png';

const Footer = () => {
  const navigate = useNavigate();

  const handleScrollLink = (section) => {
    navigate('/');
    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }, 100);
  };

  return (
    <footer className="bg-[#1a1a1a] w-full text-white py-8">
      <div className="lg:w-4/6 w-full mx-auto px-4 sm:px-6 lg:px-4">
      <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          <div className="flex items-center mb-4 md:mb-0">
          <img src={IconLogo} alt="DripChip" className="h-10" />

          </div>
          <div className="flex flex-wrap justify-center mb-4 md:mb-0">
            <div className="mx-4 cursor-pointer hover:text-[#ff6b6b] transition-colors" onClick={() => handleScrollLink('home')}>Home</div>
            <div className="mx-4 cursor-pointer hover:text-[#ff6b6b] transition-colors" onClick={() => handleScrollLink('products')}>Products</div>
          </div>
          <div className="flex">
            <a href="https://twitter.com/dripchipxyz" target="_blank" rel="noopener noreferrer" className="mx-2 hover:text-[#ff6b6b] transition-colors">
              <TwitterIcon />
            </a>
            <a href="https://instagram.com/dripchipxyz" target="_blank" rel="noopener noreferrer" className="mx-2 hover:text-[#ff6b6b] transition-colors">
              <InstagramIcon />
            </a>
            <a href="https://tiktok.com/@dripchipxyz" target="_blank" rel="noopener noreferrer" className="mx-2 hover:text-[#ff6b6b] transition-colors">
              <MusicNoteIcon />
            </a>
          </div>
        </div>
        <div className="border-t border-gray-700 pt-8 flex flex-col items-center">
          <img src={MollieBadge} alt="Payment Options" className="w-64 mb-4 md:self-end" />
          <p className="text-sm text-gray-400 mb-4">&copy;2024 DripChip. All rights reserved.</p>
          <div className="flex flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0 md:space-x-4">
            <Link to="/returns-refunds" className="text-xs text-gray-400 hover:text-[#ff6b6b] transition-colors">Returns & Refund Policy</Link>
            <Link to="/terms-of-sale" className="text-xs text-gray-400 hover:text-[#ff6b6b] transition-colors">Terms of Sale</Link>
            <Link to="/terms-of-service" className="text-xs text-gray-400 hover:text-[#ff6b6b] transition-colors">Terms of Service</Link>
            <Link to="/privacy-policy" className="text-xs text-gray-400 hover:text-[#ff6b6b] transition-colors">Privacy Policy</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
// src/utils/spotifyApi.js
import axios from 'axios';

const SPOTIFY_TOKEN_URL = 'https://accounts.spotify.com/api/token';
const SPOTIFY_API_BASE_URL = 'https://api.spotify.com/v1';

let accessToken = null;
let tokenExpirationTime = null;

const getAccessToken = async () => {
  if (accessToken && tokenExpirationTime > Date.now()) {
    return accessToken;
  }

  const params = new URLSearchParams();
  params.append('grant_type', 'client_credentials');

  try {
    const response = await axios.post(SPOTIFY_TOKEN_URL, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      auth: {
        username: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
        password: process.env.REACT_APP_SPOTIFY_CLIENT_SECRET,
      },
    });

    accessToken = response.data.access_token;
    tokenExpirationTime = Date.now() + response.data.expires_in * 1000;
    return accessToken;
  } catch (error) {
    console.error('Error getting Spotify access token:', error);
    throw error;
  }
};

const spotifyApi = axios.create({
  baseURL: SPOTIFY_API_BASE_URL,
});

spotifyApi.interceptors.request.use(async (config) => {
  const token = await getAccessToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export const getArtist = async (artistId) => {
  try {
    const response = await spotifyApi.get(`/artists/${artistId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching artist data:', error);
    throw error;
  }
};

export const searchArtists = async (query) => {
  try {
    const response = await spotifyApi.get('/search', {
      params: {
        q: query,
        type: 'artist',
        limit: 8,
      },
    });
    return response.data.artists.items;
  } catch (error) {
    console.error('Error searching artists:', error);
    throw error;
  }
};

export const getUserPlaylists = async (userId, limit = 20) => {
    try {
      const response = await spotifyApi.get(`/users/${userId}/playlists`, {
        params: { limit: limit }
      });
      return response.data.items;
    } catch (error) {
      console.error('Error fetching user playlists:', error);
      throw error;
    }
  };

export default spotifyApi;
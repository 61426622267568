import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import HomeHero from '../components/HomeHero';
import About from '../components/About';
import Products from '../components/Products';
import Newsletter from '../components/Newsletter';

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const section = location.hash.slice(1);
      scroller.scrollTo(section, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }
  }, [location]);

  return (
    <>
      <HomeHero />
      <About />
      <Products />
      <Newsletter />
    </>
  );
};

export default Home;
// src/pages/Profile.js
import React, { useState, useEffect } from 'react';
import { searchArtists, getUserPlaylists } from '../utils/spotifyApi';

const Profile = () => {
  const [user, setUser] = useState({
    username: "JohnDoe",
    profilePicture: "https://via.placeholder.com/150",
    coverPhoto: "https://via.placeholder.com/1000x300",
    followers: 1234,
    syncs: 567,
  });
  const [topArtists, setTopArtists] = useState([]);
  const [playlists, setPlaylists] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const artists = await searchArtists('pop');
        setTopArtists(artists);

        const userPlaylists = await getUserPlaylists('spotify', 6);
        setPlaylists(userPlaylists);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="bg-[#121212] min-h-screen">
      <header className="relative h-[300px] mb-20">
        <div className="w-full h-full bg-cover bg-center" style={{backgroundImage: `url(${user.coverPhoto})`}}></div>
        <div className="absolute -bottom-[60px] left-1/2 transform -translate-x-1/2 text-center">
          <div className="w-[150px] h-[150px] rounded-full bg-cover bg-center border-5 border-[#121212] mx-auto mb-5" style={{backgroundImage: `url(${user.profilePicture})`}}></div>
          <h1 className="text-white text-3xl m-0">{user.username}</h1>
        </div>
      </header>
      <main className="max-w-3xl mx-auto px-5">
        <section className="flex justify-around bg-[#1a1a1a] rounded-lg p-5 mb-5">
          <div className="text-center">
            <h3 className="text-[#ff6b6b] m-0">Followers</h3>
            <p className="text-white text-2xl mt-1 mb-0">{user.followers}</p>
          </div>
          <div className="text-center">
            <h3 className="text-[#ff6b6b] m-0">Syncs</h3>
            <p className="text-white text-2xl mt-1 mb-0">{user.syncs}</p>
          </div>
        </section>

        <section className="bg-[#1a1a1a] rounded-lg p-5 mb-5">
          <h2 className="text-[#ff6b6b] mt-0">Playlists</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
            {playlists.map(playlist => (
              <div key={playlist.id} className="flex flex-col items-center bg-[#2a2a2a] rounded-lg p-4 text-center">
                <img src={playlist.images[0]?.url || "https://via.placeholder.com/100"} alt={playlist.name} className="w-full h-auto rounded-lg mb-2.5" />
                <h3 className="text-white m-0 mb-1 text-base">{playlist.name}</h3>
                <p className="text-[#b3b3b3] m-0 text-sm">{playlist.tracks.total} tracks</p>
              </div>
            ))}
          </div>
        </section>

        <section className="bg-[#1a1a1a] rounded-lg p-5 mb-5">
          <h2 className="text-[#ff6b6b] mt-0">Top Artists</h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5">
            {topArtists.map(artist => (
              <div key={artist.id} className="bg-[#2a2a2a] rounded-lg p-4 text-center">
                <img src={artist.images[0]?.url || "https://via.placeholder.com/100"} alt={artist.name} className="w-[100px] h-[100px] rounded-full mx-auto mb-2.5" />
                <p className="text-white">{artist.name}</p>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};

export default Profile;
import React from 'react';
import PolicyPage from '../pages/PolicyPage';

const TermsOfSale = () => {
  const content = (
    <>
      <h2>Terms of Sale</h2>
      <p>By placing an order with DripChip, you are offering to purchase a product, and representing that you are 18 years or older.</p>
      <h2>Pricing and Availability</h2>
      <p>All prices are subject to change without notice. Every effort has been made to ensure accurate pricing of the products featured on our site. In the event a product is listed at an incorrect price due to typographical error, we shall have the right to refuse or cancel any orders placed for product listed at the incorrect price.</p>
      <h2>Payment</h2>
      <p>We accept various forms of payment, including credit cards and PayPal. By submitting your order, you represent and warrant that you have the legal right to use any payment method(s) you select.</p>
    </>
  );

  return <PolicyPage title="Terms of Sale" content={content} />;
};

export default TermsOfSale;
import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import * as THREE from 'three';

const CDCaseModel = ({ selectedTextures, activeCanvas }) => {
    const groupRef = useRef();
    const directionalLightRef = useRef();
    const lightTargetRef = useRef(new THREE.Object3D());
    const cubeCamera = useRef();
    const { scene, gl } = useThree();
    const loader = useMemo(() => new THREE.TextureLoader(), []);

    const [fbx, setFbx] = useState(null);

    useEffect(() => {
        const fbxLoader = new FBXLoader();
        fbxLoader.load('/cdcase.fbx', (loadedFbx) => {
            setFbx(loadedFbx);
        }, undefined, (error) => {
            console.error('An error occurred while loading the FBX file:', error);
        });
    }, []);

    

    const createMaterial = useCallback((name) => {
        if (name === 'BézierCircle' || name === 'Sphere') {
            return new THREE.MeshStandardMaterial({
                color: 0x808080,
                metalness: 0.65,
                roughness: 0.2,
                side: THREE.DoubleSide,
            });
        }
        return new THREE.MeshPhongMaterial({
            color: 0xcccccc,
            transparent: true,
            opacity: 0.7,
            shininess: 100,
            side: THREE.DoubleSide,
        });
    }, []);

    const [model, parts] = useMemo(() => {
        if (!fbx) return [null, {}];
        const clonedModel = fbx.clone();
        const partsObj = {};
        clonedModel.traverse((child) => {
            if (child.isMesh) {
                partsObj[child.name] = child;
                child.material = createMaterial(child.name);
                child.castShadow = true;
                child.receiveShadow = true;
                console.log(`Found mesh: ${child.name}`, child);
            }
        });

        function logHierarchy(object, indent = '') {
            console.log(indent + object.name + ' (' + object.type + ')');
            object.children.forEach(child => logHierarchy(child, indent + '  '));
          }
          
          // In your useMemo hook, after cloning the model:
          console.log("Model hierarchy:");
          logHierarchy(clonedModel);
      
        // Add this line to log all mesh names
        console.log("Model parts:", Object.keys(partsObj));
      
        return [clonedModel, partsObj];
      }, [fbx, createMaterial]);

    const cubeRenderTarget = useMemo(() => new THREE.WebGLCubeRenderTarget(256, {
        format: THREE.RGBFormat,
        generateMipmaps: true,
        minFilter: THREE.LinearMipmapLinearFilter,
        encoding: THREE.SRGBColorSpace,
    }), []);

    const setupScene = useCallback(() => {
        if (!scene) return;

        const ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
        scene.add(ambientLight);
    
        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
        directionalLight.position.set(0, 10, 5);
        directionalLightRef.current = directionalLight;
        scene.add(directionalLight);
    
        const canvas = document.createElement('canvas');
        canvas.width = 512;
        canvas.height = 512;
        const context = canvas.getContext('2d');
    
        const gradient = context.createRadialGradient(256, 256, 0, 256, 256, 256);
        gradient.addColorStop(0, 'rgba(0, 0, 0, 0.4)');
        gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');
        context.fillStyle = gradient;
        context.fillRect(0, 0, 512, 512);
    
        const groundTexture = new THREE.CanvasTexture(canvas);
        groundTexture.wrapS = THREE.RepeatWrapping;
        groundTexture.wrapT = THREE.RepeatWrapping;
        groundTexture.repeat.set(1, 1);
    
        const groundGeometry = new THREE.PlaneGeometry(10, 10);
        const groundMaterial = new THREE.MeshBasicMaterial({
            map: groundTexture,
            transparent: true,
            opacity: 0.5,
            depthWrite: false
        });
        const groundPlane = new THREE.Mesh(groundGeometry, groundMaterial);
        groundPlane.rotation.x = -Math.PI / 2;
        groundPlane.position.y = -3;
        scene.add(groundPlane);
    
        cubeCamera.current = new THREE.CubeCamera(1, 1000, cubeRenderTarget);
        scene.add(cubeCamera.current);
    }, [scene, cubeRenderTarget]);

    useEffect(() => {
        if (model) {
            setupScene();
            return () => {
                if (scene && cubeCamera.current) {
                    scene.remove(cubeCamera.current);
                }
                if (cubeRenderTarget) {
                    cubeRenderTarget.dispose();
                }
            };
        }
    }, [model, setupScene, scene, cubeRenderTarget]);

    useEffect(() => {
        if (model && parts) {
            const box = new THREE.Box3().setFromObject(model);
            const center = box.getCenter(new THREE.Vector3());
            model.position.sub(center);
            model.position.y += 1.3;
    
            if (parts['Cube006'] && parts['Cube002']) {
                parts['Cube006'].geometry.computeBoundingBox();
                parts['Cube002'].geometry.computeBoundingBox();
    
                const frontBBox = parts['Cube006'].geometry.boundingBox;
                const frontWidth = frontBBox.max.x - frontBBox.min.x;
    
                // Adjust position relative to Cube002 if necessary
                parts['Cube006'].position.copy(parts['Cube002'].position);
                parts['Cube006'].position.x -= frontWidth / 16;
                parts['Cube006'].position.z += 0.01; // Slight offset to prevent z-fighting
    
                parts['Cube006'].rotation.order = 'YXZ';
    
                // Ensure Cube009 and Cube003 are properly positioned within their parents
                const frontInner = parts['Cube006'].children.find(c => c.name === 'Cube009');
                const backInner = parts['Cube002'].children.find(c => c.name === 'Cube003');
                if (frontInner) frontInner.position.set(0, 0, 0);
                if (backInner) backInner.position.set(0, 0, 0);
            }
        }
    }, [model, parts]);

    const applyTexture = useCallback((meshName, texture, isCD = false) => {
        console.log(`Applying texture to ${meshName}`, texture);
        if (parts[meshName] && texture) {
            const textureLoader = new THREE.TextureLoader();
            textureLoader.load(texture.src, (loadedTexture) => {
                console.log(`Texture loaded for ${meshName}`);
                loadedTexture.colorSpace = THREE.SRGBColorSpace;
                
                let targetMesh = parts[meshName];
                
                if (!isCD) {
                    // For covers (006 and 002), apply to the child mesh
                    const childName = meshName === 'Cube006' ? 'Cube009' : 'Cube003';
                    targetMesh = targetMesh.children.find(child => child.name === childName) || targetMesh;
                }
                
                if (targetMesh && targetMesh.isMesh) {
                    console.log(`Applying texture to mesh: ${targetMesh.name}`);
                    targetMesh.material.map = loadedTexture;
                    targetMesh.material.envMap = cubeRenderTarget.texture;
                    targetMesh.material.needsUpdate = true;
                } else {
                    console.log(`Failed to find appropriate mesh for ${meshName}`);
                }
            });
        } else {
            console.log(`Missing parts[${meshName}] or texture`);
        }
    }, [parts, cubeRenderTarget]);

    useEffect(() => {
        if (model && parts && cubeCamera.current) {
            if (selectedTextures.front) applyTexture('Cube006', selectedTextures.front);
            if (selectedTextures.back) applyTexture('Cube002', selectedTextures.back);
            if (selectedTextures.cd) applyTexture('Cube004', selectedTextures.cd, true);
        }
    }, [model, selectedTextures, parts, cubeCamera, applyTexture]);
    
    useEffect(() => {
        if (scene && lightTargetRef.current) {
            scene.add(lightTargetRef.current);
        }
    }, [scene]);

    useFrame((state, delta) => {
        if (groupRef.current && parts['Cube006'] && directionalLightRef.current) {
            updateCDCaseRotation();
            updateReflections();
        }
    });

    const updateCDCaseRotation = () => {
        const { targetRotation, targetOpenAngle } = getTargetAngles();
        if (groupRef.current) {
            groupRef.current.rotation.y += (targetRotation - groupRef.current.rotation.y) * 0.1;
        }
        if (parts['Cube006']) {
            parts['Cube006'].rotation.y += (targetOpenAngle - parts['Cube006'].rotation.y) * 0.1;
        }
    };

    const getTargetAngles = () => {
        switch (activeCanvas) {
            case 'back':
                return { targetRotation: Math.PI, targetOpenAngle: 0 };
            case 'cd':
                return { targetRotation: 0, targetOpenAngle: -Math.PI * 0.75 };
            default:
                return { targetRotation: 0, targetOpenAngle: 0 };
        }
    };

    const updateReflections = useCallback(() => {
        if (cubeCamera.current && gl && scene) {
            const reflectiveParts = ['Cube006', 'Cube002', 'Cube004'];
            
            reflectiveParts.forEach(partName => {
                if (parts[partName]) {
                    parts[partName].visible = false;
                    if (partName === 'Cube006' || partName === 'Cube002') {
                        const child = parts[partName].children.find(c => c.name === (partName === 'Cube006' ? 'Cube009' : 'Cube003'));
                        if (child) child.visible = false;
                    }
                }
            });
    
            cubeCamera.current.update(gl, scene);
    
            reflectiveParts.forEach(partName => {
                if (parts[partName]) {
                    parts[partName].visible = true;
                    if (partName === 'Cube006' || partName === 'Cube002') {
                        const child = parts[partName].children.find(c => c.name === (partName === 'Cube006' ? 'Cube009' : 'Cube003'));
                        if (child) child.visible = true;
                    }
                }
            });
        }
    }, [cubeCamera, gl, scene, parts]);

    if (!model) return null;
    
    return (
        <group ref={groupRef} castShadow>
            <primitive object={model} scale={[0.015, 0.015, 0.015]} castShadow receiveShadow />
        </group>
    );
};

export default React.memo(CDCaseModel);
import React from 'react';
import PolicyPage from '../pages/PolicyPage';

const ReturnsRefundPolicy = () => {
  const content = (
    <>
      <h2>Returns & Refund Policy</h2>
      <p>Our policy lasts 30 days. If 30 days have gone by since your purchase, unfortunately we can't offer you a refund or exchange.</p>
      <h2>To be eligible for a return, your item must be:</h2>
      <ul>
        <li>Unused and in the same condition that you received it</li>
        <li>In the original packaging</li>
      </ul>
      <h2>Refunds</h2>
      <p>Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.</p>
      <p>If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.</p>
    </>
  );

  return <PolicyPage title="Returns & Refund Policy" content={content} />;
};

export default ReturnsRefundPolicy;
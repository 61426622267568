import React, { useState } from 'react';
import { Element } from 'react-scroll';

const Newsletter = () => {
  const [email, setEmail] = useState('');

  const handleNewsletterSubmit = (e) => {
    e.preventDefault();
    console.log('Newsletter subscription:', email);
    setEmail('');
  };

  return (
    <Element name="newsletter" className="bg-[#292929] xl:w-4/6 w-full mx-auto py-16 px-5">
      <div className="max-w-lg mx-auto">
        <h2 className="text-3xl-s font-bold mb-6 text-white text-center uppercase">Subscribe to Our Newsletter</h2>
        <form onSubmit={handleNewsletterSubmit} className="flex flex-col w-full">
          <input 
            type="email" 
            placeholder="Your Email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
            className="mb-4 p-2 rounded-md border-none focus:ring-2 focus:ring-green-500 focus:outline-none"
          />
          <button 
            type="submit"
            className="block py-2 px-1 bg-green-500 rounded-lg text-white cursor-pointer transition uppercase duration-300 hover:bg-gray-700 text-center mb-6"
          >
            Subscribe
          </button>
        </form>
      </div>
    </Element>
  );
};

export default Newsletter;
import React from 'react';
import { Element } from 'react-scroll';

const About = () => (
  <Element name="about" className="bg-[#1a1a1a] min-h-[400px] xl:w-4/6 w-full mx-auto flex items-center justify-center text-white py-16 px-5">
    <div className="max-w-6xl w-full mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:gap-8 gap-12 justify-items-center items-center">
        <div className="text-center lg:w-48 w-72  flex flex-col items-center justify-center">
          <h3 className="text-2xl font-bold uppercase mb-4">Tap</h3>
          <p className="text-lg">
            Simply tap your DripChip to any NFC-enabled device.
          </p>
        </div>
        <div className="text-center lg:w-48 w-72  flex flex-col items-center justify-center">
          <h3 className="text-2xl font-bold uppercase mb-4">Connect</h3>
          <p className="text-lg">
            Your device instantly connects to your unique playlist.
          </p>
        </div>
        <div className="text-center lg:w-48 w-72  flex flex-col items-center justify-center">
          <h3 className="text-2xl font-bold uppercase mb-4">Play</h3>
          <p className="text-lg">
            Enjoy your favorite tunes anytime, anywhere.
          </p>
        </div>
      </div>
    </div>
  </Element>
);

export default About;
import React from 'react';
import { Element } from 'react-scroll';
import HeroImage from '../assets/image.png';

const HomeHero = () => (
  <Element 
    name="home" 
    style={{ 
      backgroundImage: `url(${HeroImage})`,
      backgroundPosition: 'center bottom 35%'
    }}
    className="bg-[#1a1a1a] xl:w-4/6 w-full mx-auto bg-cover bg-no-repeat text-white py-16 px-5 h-[400px] flex flex-col justify-center items-center"
  >
  </Element>
);

export default HomeHero;
import React from 'react';
import PolicyPage from '../pages/PolicyPage';

const PrivacyPolicy = () => {
  const content = (
    <>
      <h2>Privacy Policy</h2>
      <p>Your privacy is important to us. It is DripChip's policy to respect your privacy regarding any information we may collect from you across our website, https://www.dripchip.xyz, and other sites we own and operate.</p>
      <h2>Information We Collect</h2>
      <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and how it will be used.</p>
      <h2>Data Protection</h2>
      <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we'll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.</p>
      <h2>Your Rights</h2>
      <p>You have the right to access, update or to delete the personal information we have on you. Whenever made possible, you can update your Personal Data directly within your account settings section.</p>
    </>
  );

  return <PolicyPage title="Privacy Policy" content={content} />;
};

export default PrivacyPolicy;